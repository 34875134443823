<template>
  <v-container fluid class="down-top-padding">
    <div id="invoicesPage" class="page">
      <div class="page__header ">
        <Toolbar
          :showFiltersPanel="showFiltersPanel"
          @showFiltersPanelChanged="handleShowFiltersPanelChanged"
          @toggleDialog="toggleDialog"
          @toggleStaticDataDialog="toggleStaticDataDialog"
        />
      </div>
      <div class="page__body pa-4">
        <div class="mb-4" v-show="showFiltersPanel">
          <v-row class="my-0">
            <v-col md="12">
              <Filters @filtersChanged="handleFiltersChanged" />
            </v-col>
          </v-row>
        </div>

        <v-dialog
          v-model="staticDataDialog"
          persistent
          scrollable
          max-width="1000px"
        >
          <StaticDataForm
            v-if="staticDataDialog"
            @staticDataFormSubmit="handleStaticDataFormSubmit"
            :toggleStaticDataDialog="toggleStaticDataDialog"
            :isStaticDataSubmittingForm="isStaticDataSubmittingForm"
            :selectedInvoiceStaticData="selectedInvoiceStaticData"
          />
        </v-dialog>

        <v-dialog v-model="dialog" persistent scrollable max-width="1000px">
          <Form
            v-if="dialog"
            @formSubmit="handleFormSubmit"
            :toggleDialog="toggleDialog"
            :isSubmittingForm="isSubmittingForm"
            :selectedInvoice="selectedInvoice"
          />
        </v-dialog>
        <List
          :page="page"
          :items="items"
          :totalCount="totalCount"
          :isFetchingItems="isFetchingItems"
          :itemsPerPage="itemsPerPage"
          @editItem="handleEditItem"
          @downloadItem="handleDownloadItem"
          @deleteItem="handleDeleteItem"
          @optionsChanged="handleOptionsChanged"
          @changeStatus="handleChangeStatus"
        />
      </div>
      <ConfirmationPopup
        id="invoice-delete-modal"
        v-if="invoiceToDelete"
        title="Hesabın silinməsi"
        :dialog="confirmationDialog"
        :loading="isDeletingInvoice"
        @confirm="handleDeleteConfirmation"
        @cancel="handleDeleteCancellation"
      >
        Hesabı silmək istədiyinizə əminsiniz?
      </ConfirmationPopup>
    </div>
  </v-container>
</template>

<script>
import API from "@/services";
import ConfirmationPopup from "@/shared/Common/ConfirmationPopup.vue";
import fileDownload from "js-file-download";
import List from "./components/List/index.vue";
import Form from "./components/Form/index.vue";
import StaticDataForm from "./components/StaticDataForm/index.vue";
import Toolbar from "./components/Toolbar/index.vue";
import Filters from "./components/Toolbar/components/Filters.vue";
import bus from "../../../event-bus";

export default {
  components: {
    List,
    Form,
    StaticDataForm,
    Toolbar,
    ConfirmationPopup,
    Filters,
  },
  data() {
    return {
      dialog: false,
      staticDataDialog: false,
      confirmationDialog: false,
      selectedInvoice: null,
      selectedInvoiceStaticData: null,
      invoiceToDelete: null,
      page: 1,
      isFetchingItems: false,
      isSubmittingForm: false,
      isStaticDataSubmittingForm: false,
      isDeletingInvoice: false,
      totalCount: 0,
      itemsPerPage: 10,
      items: [],
      filters: {},
      showFiltersPanel: false,
    };
  },
  async created() {
    this.fetchInvoices();
  },
  methods: {
    fetchInvoices() {
      this.isFetchingItems = true;
      API.fetchInvoices({
        limit: this.itemsPerPage,
        offset: (this.page - 1) * this.itemsPerPage,
        ...this.filters,
      })
        .then((response) => {
          this.items = response.items;
          this.totalCount = response.totalCount;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Hesabların siyahısını əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleFormSubmit(formData) {
      this.isSubmittingForm = true;
      const request = this.selectedInvoice
        ? API.updateInvoice(this.selectedInvoice.id, formData)
        : API.insertInvoice(formData);

      request
        .then(() => {
          this.toggleDialog();
          this.fetchInvoices();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isSubmittingForm = false;
        });
    },
    handleEditItem(item) {
      this.isFetchingItems = true;
      API.fetchInvoiceById(item.id)
        .then((response) => {
          this.selectedInvoice = response;
          this.dialog = true;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Məlumatı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleDownloadItem(item) {
      var invoiceId;
      API.fetchInvoiceId().then((response) => {
        invoiceId = response;
      });

      API.downloadInvoice(item.id)
        .then((response) => {
          if (response)
            fileDownload(
              response,
              `Hesab ${invoiceId} ${item.invoiceName}.docx`
            );
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Hesabı əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {});
    },
    handleDeleteItem(item) {
      this.invoiceToDelete = item;
      this.confirmationDialog = true;
    },
    handleDeleteConfirmation() {
      this.isDeletingInvoice = true;
      API.deleteInvoice(this.invoiceToDelete.id)
        .then(() => {
          this.confirmationDialog = false;
          this.invoiceToDelete = null;
          this.fetchInvoices();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Hesabı silmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isDeletingInvoice = false;
        });
    },
    handleDeleteCancellation() {
      this.confirmationDialog = false;
      this.invoiceToDelete = null;
    },
    handleFiltersChanged(filters) {
      if (JSON.stringify(filters) !== JSON.stringify(this.filters)) {
        this.page = 1;
        this.filters = { ...filters };
        this.fetchInvoices();
      }
    },
    handleChangeStatus(item) {
      this.isFetchingItems = true;

      API.changeInvoiceStatus(item.id)
        .then(() => {
          this.fetchInvoices();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Hesabın statusunu dəyişmək mümkün olmadı.",
          });
        });
    },

    toggleDialog() {
      this.dialog = !this.dialog;
      if (!this.dialog) {
        this.selectedInvoice = null;
      }
    },
    toggleStaticDataDialog() {
      this.handleStaticDataEditItem();
      this.staticDataDialog = !this.staticDataDialog;
      if (!this.staticDataDialog) {
        this.selectedInvoiceStaticData = null;
      }
    },
    handleStaticDataEditItem() {
      this.isFetchingItems = true;
      API.fetchInvoiceStaticData()
        .then((response) => {
          this.selectedInvoiceStaticData = response;
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Statik məlumatları əldə etmək mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isFetchingItems = false;
        });
    },
    handleStaticDataFormSubmit(formStaticData) {
      this.isStaticDataSubmittingForm = true;

      API.updateInvoiceStaticData(formStaticData)
        .then(() => {
          this.toggleStaticDataDialog();
        })
        .catch(() => {
          bus.$emit("notificationMessage", {
            color: "error",
            text: "Əməliyyatı aparmaq mümkün olmadı.",
          });
        })
        .finally(() => {
          this.isStaticDataSubmittingForm = false;
        });
    },
    handleOptionsChanged(item) {
      if (this.page !== item.page || this.itemsPerPage !== item.itemsPerPage) {
        this.page = item.page;
        this.itemsPerPage = item.itemsPerPage;

        this.fetchInvoices();
      }
    },
    handleShowFiltersPanelChanged(item) {
      this.showFiltersPanel = item;
    },
  },
};
</script>
