<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        {{ isEditMode ? "Hesabın redaktəsi" : "Yeni hesab" }}
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="createInvoiceForm"
        ref="form"
        v-model="formValidation"
        lazy-validation
        @submit.prevent="handleFormSubmit"
      >
        <v-row>
          <v-col cols="6">
            <span class="ml-2">Cari ay?</span>
          </v-col>
          <v-col cols="6">
            <v-radio-group
              class="my-0 pt-0"
              v-model="formData.isCurrentMonth"
              row
              hide-details
            >
              <v-radio class="ml-2" label="Hə" :value="true"></v-radio>
              <v-radio class="ml-2" label="Yox" :value="false"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <span class="ml-2">Tək ay?</span>
          </v-col>
          <v-col cols="6">
            <v-radio-group
              class="my-0 pt-0"
              v-model="formData.isSomeMonths"
              row
              hide-details
            >
              <v-radio class="ml-2" label="Hə" :value="false"></v-radio>
              <v-radio class="ml-2" label="Yox" :value="true"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="Adı*"
              filled
              dense
              v-model="formData.invoiceName"
              :rules="formRules.invoiceName"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="Kodu*"
              filled
              dense
              v-model="formData.invoiceCode"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea
              type="text"
              label="FilePath"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.invoiceFilePath"
            ></v-textarea>
          </v-col>

          <v-col cols="12">
            <v-textarea
              type="text"
              label="Təsviri"
              filled
              dense
              required
              auto-grow
              rows="1"
              v-model="formData.description"
              :rules="formRules.description"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isSubmittingForm"
        type="submit"
        form="createInvoiceForm"
        color="success"
        depressed
      >
        {{ isEditMode ? "Yadda saxla" : "Yarat" }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleDialog: {
      type: Function,
      required: true,
    },
    isSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedInvoice: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      formValidation: false,
      formData: {
        invoiceName: "",
        invoiceCode: "",
        invoiceFilePath: "",
        description: "",
        isCurrentMonth: true,
        isSomeMonths: false,
      },
      formRules: {
        invoiceName: [(v) => !!v || "Hesabın adını daxil edin"],
        invoiceCode: [
          (v) => !this.formData.isStatic || !!v || "Hesabın kodunu daxil edin",
        ],
      },
    };
  },
  computed: {
    isEditMode() {
      return (
        this.selectedInvoice &&
        this.selectedInvoice.id &&
        this.selectedInvoice.id > 0
      );
    },
  },
  watch: {
    selectedInvoice: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleFormSubmit() {
      const isFormValid = this.$refs.form.validate();
      if (isFormValid) {
        this.$emit("formSubmit", this.formData);
      }
    },
    resetFormData() {
      this.formData = {
        invoiceName: "",
        invoiceCode: "",
        invoiceFilePath: "",
        description: "",
        isCurrentMonth: true,
        isSomeMonths: false,
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
  },
};
</script>
