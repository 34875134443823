<template>
  <v-card>
    <v-card-title class="py-3 px-4 border-bottom">
      <span class="gray--text text--darken-3 font-weight-regular">
        Statik məlumatların redaktəsi
      </span>
    </v-card-title>
    <v-card-text>
      <v-form
        id="updateInvoiceStaticDataForm"
        ref="form"
        lazy-validation
        @submit.prevent="handleStaticDataFormSubmit"
      >
        <v-row>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="Son Hesab nömrə"
              filled
              dense
              v-model="formStaticData.maxInvoiceId"
              disabled
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="Hesab nömrə"
              filled
              dense
              v-model="formStaticData.invoiceId"
              required
            ></v-text-field>
          </v-col>

          <v-col cols="4">
            <v-text-field
              type="text"
              label="Tarix"
              filled
              dense
              v-model="formStaticData.date"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="text"
              label="Cari ay"
              filled
              dense
              v-model="formStaticData.month"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="text"
              label="Köhnə ay"
              filled
              dense
              v-model="formStaticData.lastMonth"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="text"
              label="3 Ay"
              filled
              dense
              v-model="formStaticData.months"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="text"
              label="6 Ay"
              filled
              dense
              v-model="formStaticData.sixMonths"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="4">
            <v-text-field
              type="text"
              label="12 ay"
              filled
              dense
              v-model="formStaticData.fullMonths"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="İl"
              filled
              dense
              v-model="formStaticData.year"
              required
            ></v-text-field>
          </v-col>
          <v-col cols="6">
            <v-text-field
              type="text"
              label="Date in English"
              filled
              dense
              v-model="formStaticData.engDate"
              required
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-card-actions class="py-3 px-4 d-flex justify-end border-top">
      <v-btn color="gray lighten-3" depressed @click="toggleStaticDataDialog">
        Bağla
      </v-btn>
      <v-btn
        :loading="isStaticDataSubmittingForm"
        type="submit"
        form="updateInvoiceStaticDataForm"
        color="success"
        depressed
      >
        Yadda saxla
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    toggleStaticDataDialog: {
      type: Function,
      required: true,
    },
    isStaticDataSubmittingForm: {
      type: Boolean,
      required: true,
    },
    selectedInvoiceStaticData: {
      type: Object,
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      formStaticData: {
        maxInvoiceId: "",
        date: "",
        invoiceId: "",
        year: "",
        month: "",
        lastMonth: "",
        months: "",
        sixMonths: "",
        fullMonths: "",
        engDate: "",
      },
    };
  },
  watch: {
    selectedInvoiceStaticData: {
      immediate: true,
      handler(newVal) {
        if (newVal) {
          this.formStaticData = { ...newVal };
        } else {
          this.resetFormData();
        }
      },
    },
  },
  methods: {
    handleStaticDataFormSubmit() {
      this.$emit("staticDataFormSubmit", this.formStaticData);
    },
    resetFormData() {
      this.formStaticData = {
        maxInvoiceId: "",
        date: "",
        invoiceId: "",
        year: "",
        month: "",
        lastMonth: "",
        months: "",
        sixMonths: "",
        fullMonths: "",
        engDate: "",
      };
      this.$refs.form && this.$refs.form.resetValidation();
    },
  },
};
</script>
